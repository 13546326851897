import { IEnumType } from './utils/api.d';

const Unknown: IEnumType = { id: 0, value: 'Unknown', name: 'Unknown' };

export const projectType = {
  Unknown,
  Webcast: {
    value: 'Webcast',
    name: 'Webcast',
    desc: 'Studio/MMC Essential and Expert',
    id: 1,
  },
  VirtualEvent: {
    value: 'VirtualEvent',
    desc: 'VE Express, Essential and Expert',
    name: 'Virtual Event',
    id: 2,
  },
  Plan: {
    value: 'Plan',
    desc: 'Self-Service Studio, Portals, eCDN',
    name: 'Plan',
    id: 3,
  },
  ProjectGroup: {
    value: 'ProjectGroup',
    desc: 'Group multiple projects together',
    name: 'Project Group',
    id: 4,
  },
  ResellerPlan: {
    value: 'ResellerPlan',
    desc: 'Track reseller projects',
    name: 'Reseller Plan',
    id: 5,
  },
  Misc: {
    value: 'Misc',
    desc: 'Misc projects',
    name: 'Misc',
    id: 6,
  },
  WebcastSubProject: {
    value: 'WebcastSubProject',
    name: 'Webcast Sub-Project',
    desc: 'Studio/MMC Essential and Expert',
    id: 7,
  },
  VirtualEventSubProject: {
    value: 'VirtualEventSubProject',
    desc: 'VE Express, Essential and Expert',
    name: 'VE Sub-Project',
    id: 8,
  },
  PlatformPlanSubProject: {
    value: 'PlatformPlanSubProject',
    desc: 'Self-Service Studio, Portals, eCDN',
    name: 'Plan Sub-Project',
    id: 9,
  },
};

export const projectSubType = {
  Unknown,
  MMCEssentials: { id: 1, value: 'MMCEssentials', name: 'MMC Essentials' },
  MMCExpert: { id: 2, value: 'MmcExpert', name: 'MMC Expert' },
  StudioEssentials: { id: 3, value: 'StudioEssentials', name: 'Studio Essentials' },
  StudioExpert: { id: 4, value: 'StudioExpert', name: 'Studio Expert' },
  VEExpress: { id: 5, value: 'VEExpress', name: 'VE Express' },
  VEEssentials: { id: 6, value: 'VEEssentials', name: 'VE Essentials' },
  VEExpert: { id: 7, value: 'VEExpert', name: 'VE Expert' },
  MMC: { id: 8, value: 'MMC', name: 'MMC' },
  Studio: { id: 9, value: 'Studio', name: 'Studio' },
  eCDN: { id: 10, value: 'eCDN', name: 'eCDN' },
  Portal: { id: 11, value: 'Portal', name: 'Portal' },
  Other: { id: 12, value: 'Other', name: 'Other' },
};

export const serviceModelType = {
  Unknown,
  FullService: { id: 1, value: 'FullService', name: 'Full Service' },
  HybridService: { id: 2, value: 'HybridService', name: 'Hybrid Service' },
  SelfService: { id: 3, value: 'SelfService', name: 'Self Service' },
};

export const usageType = {
  Unknown,
  Production: { id: 1, value: 'Production', name: 'Production' },
  Demo: { id: 2, value: 'Demo', name: 'Demo' },
  Test: { id: 3, value: 'Test', name: 'Test' },
  TelcoOnly: { id: 5, value: 'TelcoOnly', name: 'Telco Only' },
  PreRecord: { id: 6, value: 'PreRecord', name: 'Pre-Record' },
};

export const contentType = {
  Unknown,
  Video: { id: 1, value: 'Video', name: 'Video' },
  VideoHD: { id: 2, value: 'VideoHD', name: 'Video HD' },
  AudioOnly: { id: 3, value: 'AudioOnly', name: 'Audio only' },
};

export const deliveryType = {
  Unknown,
  Live: { id: 1, value: 'Live', name: 'Live' },
  SimuLive: { id: 2, value: 'SimuLive', name: 'SimuLive' },
  Ondemand: { id: 3, value: 'Ondemand', name: 'Ondemand' },
};

export const staffRoleType = {
  ProjectManager: { id: 1, value: 'ProjectManager', name: 'Project Manager' },
  Producer: { id: 2, value: 'Producer', name: 'Producer' },
  Onsite: { id: 3, value: 'Onsite', name: 'Onsite' },
};

export const platformType = {
  MmcVex: { id: 1, value: 'MmcVex', name: 'MMC/VEX' },
  StudioVeAmer: { id: 2, value: 'StudioVeAmer', name: 'Studio/VE - AMER' },
  StudioVeEmea: { id: 3, value: 'StudioVeEmea', name: 'Studio/VE - EMEA' },
  StudioVeApac: { id: 4, value: 'StudioVeApac', name: 'Studio/VE - APAC' },
};

export const eventType = {
  Webcast: { id: 1, value: 'Webcast', name: 'Webcast' },
  VirtualEvent: { id: 2, value: 'VirtualEvent', name: 'Virtual Event' },
  Portal: { id: 3, value: 'Portal', name: 'Portal' },
};

export const eventModeType = {
  Unknown,
  Prior: { id: 1, value: 'Prior', name: 'Prior' },
  Live: { id: 2, value: 'Live', name: 'Live' },
  Post: { id: 3, value: 'Post', name: 'Post' },
  OnDemand: { id: 4, value: 'OnDemand', name: 'OnDemand' },
};

export const eventSubType = {
  Unknown,
  MMCEssentials: { id: 1, value: 'MmcEssentials', name: 'MMC Essentials' },
  MMCExpert: { id: 2, value: 'MmcExpert', name: 'MMC Expert' },
  StudioEssentials: { id: 3, value: 'StudioEssentials', name: 'Studio Essentials' },
  StudioExpert: { id: 4, value: 'StudioExpert', name: 'Studio Expert' },
  VEExpress: { id: 5, value: 'VeExpress', name: 'VE Express' },
  VEEssentials: { id: 6, value: 'VeEssentials', name: 'VE Essentials' },
  VEExpert: { id: 7, value: 'VeExpert', name: 'VE Expert' },
  Portal: { id: 8, value: 'Portal', name: 'Portal' },
  Other: { id: 9, value: 'Other', name: 'Other' },
  BreakoutSession: { id: 10, value: 'BreakoutSession', name: 'Breakout Session' },
  ThirdPartyLink: { id: 11, value: 'ThirdPartyLink', name: '3rd Party Link' },
  Expand: { id: 12, value: 'Expand', name: 'Expand' },
  Evolve: { id: 13, value: 'Evolve', name: 'Evolve' },
  Enterprise: { id: 14, value: 'Enterprise', name: 'Enterprise' },
};

export const projectStatus = {
  AwaitingAssignment: { id: 1, value: 'AwaitingAssignment', name: 'Awaiting Assignment' },
  AssignedPending: { id: 2, value: 'AssignedPending', name: 'Assigned, Pending' },
  AssignedInProgress: { id: 3, value: 'AssignedInProgress', name: 'Assigned, In Progress' },
  Postponed: { id: 4, value: 'Postponed', name: 'Postponed' },
  Canceled: { id: 5, value: 'Canceled', name: 'Canceled' },
  Fulfilled: { id: 6, value: 'Fulfilled', name: 'Fulfilled' },
};

export const supportRegions = [
  { id: 1, value: 'AMER', name: 'AMER' },
  { id: 2, value: 'EMEA', name: 'EMEA' },
  { id: 3, value: 'APAC', name: 'APAC' },
  { id: 4, value: 'GLOBAL', name: 'GLOBAL' },
];

export const fulfillmentStatus = {
  PendingFulfillment: { id: 1, name: 'Pending Fulfillment', value: 'PendingFulfillment' },
  Fulfilled: { id: 2, name: 'Fulfilled', value: 'Fulfilled' },
};

export const eventCallProvider = {
  ChorusCallAMERS: { id: 1, value: 'ChorusCallAMERS', name: 'ChorusCall AMERS' },
  ChorusCallANZ: { id: 2, value: 'ChorusCallANZ', name: 'ChorusCall ANZ' },
  ChorusCallEMEA: { id: 3, value: 'ChorusCallEMEA', name: 'ChorusCall EMEA' },
  Ciptex: { id: 4, value: 'Ciptex', name: 'Ciptex' },
  Streamlined: { id: 5, value: 'Streamlined', name: 'Streamlined' },
  Wish: { id: 6, value: 'Wish', name: 'Wish' },
  Other: { id: 7, value: 'Other', name: 'Other' },
  BtAMERS: { id: 8, value: 'BtAMERS', name: 'BT AMERS (inactive)' },
  CiptexManual: { id: 9, value: 'CiptexManual', name: 'Ciptex Manual (inactive)' },
  IntercallAMERSAutomated: { id: 10, value: 'IntercallAMERSAutomated', name: 'Intercall AMERS - Automated (inactive)' },
  IntercallAMERSManual: { id: 11, value: 'IntercallAMERSManual', name: 'Intercall AMERS - Manual (inactive)' },
  IntercallANZ: { id: 12, value: 'IntercallANZ', name: 'Intercall ANZ (inactive)' },
  IntercallAsiaAPAC: { id: 13, value: 'IntercallAsiaAPAC', name: 'Intercall Asia APAC (inactive)' },
  IntercallEMEA: { id: 14, value: 'IntercallEMEA', name: 'Intercall EMEA (inactive)' },
  NttAMERS: { id: 15, value: 'NttAMERS', name: 'NTT AMERS (inactive)' },
  NttANZ: { id: 16, value: 'NttANZ', name: 'NTT ANZ (inactive)' },
  NttEMEA: { id: 17, value: 'NttEMEA', name: 'NTT EMEA (inactive)' },
  PgiANZ: { id: 18, value: 'PgiANZ', name: 'PGI ANZ (inactive)' },
  PgiEMEA: { id: 19, value: 'PgiEMEA', name: 'PGI EMEA (inactive)' },
  PgiHK: { id: 20, value: 'PgiHK', name: 'PGI HK (inactive)' },
};

export const eventLobby = {
  No: { id: 0, value: 'No', name: 'No' },
  Yes: { id: 1, value: 'Yes', name: 'Yes' },
  Unknown: { id: 2, value: 'Unknown', name: 'Unknown' },
};

export const convertEnumTypes = (type: Record<string, IEnumType>) =>
  Object.values(type).map(val => ({ value: val.value, name: val.name, id: val.id }));

export enum subscriptionStatus {
  cancelled = 'Cancelled',
}

export enum AppGrids {
  DsaList = 'DsaListGrid',
  DsaDetailsProjects = 'DsaDetailsProjectsGrid',
  DsaDetailsSubProjects = 'DsaDetailsSubProjectsGrid',
  DsaDetailsProducts = 'DsaDetailsProductsGrid',
  DsaDetailsEvents = 'DsaDetailsEventsGrid',
  ProjectsList = 'ProjectsListGrid',
  ProjectDetailsSubProjects = 'ProjectDetailsSubProjectsGrid',
  ProjectDetailsEvents = 'ProjectDetailsEventsGrid',
  ProjectDetailsTimeTracking = 'ProjectDetailsTimeTrackingGrid',
  ProjectDetailsProducts = 'ProjectDetailsProductsGrid',
  ProjectDetailsStaff = 'ProjectDetailsStaffGrid',
  EventsList = 'EventsListGrid',
  TimeTrackingList = 'TimeTrackingListGrid',
  AccountsList = 'AccountsListGrid',
  StaffList = 'StaffListGrid',
  SalesForceList = 'SalesForceListGrid',
  ReportsList = 'ReportsList',
}

export const staffRoles = {
  projectManager: { name: 'Project manager', id: 1, roleType: 'ProjectManager' },
  webcastLead: { name: 'Webcast Lead', id: 4, roleType: 'WebcastLead' },
  eventProducer: { name: 'Event Producer', id: 5, roleType: 'EventProducer' },
  proServices: { name: 'Pro Services', id: 6, roleType: 'ProServices' },
  webcastProducer: { name: 'Webcast Producer', id: 2, roleType: 'WebcastProducer' },
  webhostingServiceManager: { name: 'Webhosting Service Manager', id: 7, roleType: 'WebhostingServiceManager' },
  eventConfigurationSpecialist: {
    name: 'Event configuration specialist',
    id: 8,
    roleType: 'EventConfigurationSpecialist',
  },
  managementSponsor: { name: 'Management Sponsor', id: 9, roleType: 'ManagementSponsor' },
  watcher: { name: 'Watcher', id: 10, roleType: 'Watcher' },
};

export const dateOnlySalesForceFields = [
  'opportunityCloseDate',
  'eventFulfillmentDate',
  'contractStartDate',
  'contractEndDate',
  'subscriptionStartDate',
  'subscriptionEndDate',
  'subscriptionRenewalDate',
  'subscriptionTerminatedDate',
];
export const dateOnlyProjectsFields = ['fulfillmentStart', 'fulfillmentEnd'];
export const dateOnlyTimeTrackingFields = ['date'];

export const gridTypes = {
  ProjectsList: { id: 1, value: 'ProjectsList', name: 'Project List' },
  EventsList: { id: 2, value: 'EventsList', name: 'Events List' },
  TimeTrackingList: { id: 3, value: 'TimeTrackingList', name: 'Time Tracking List' },
};

export const mmcTypes = {
  Unknown,
  Managed: { id: 1, value: 'Managed', name: 'Managed' },
  Professional: { id: 2, value: 'Professional', name: 'Professional' },
  Enterprise: { id: 3, value: 'Enterprise', name: 'Enterprise' },
  Reseller: { id: 4, value: 'Reseller', name: 'Reseller' },
};

export const mmcProducts = {
  Unknown,
  MMC: { id: 1, value: 'MMC', name: 'MMC' },
  MMCVEX: { id: 2, value: 'MMC_VEX', name: 'MMC VEX' },
  MMCVEXBranded: { id: 3, value: 'MMC_VEX_Branded', name: 'MMC VEX Branded' },
};

export const studioServiceTypes = {
  Unknown,
  FullService: { id: 1, value: 'FullService', name: 'Full Service' },
  HybridService: { id: 2, value: 'HybridService', name: 'Hybrid Service' },
  SelfService: { id: 3, value: 'SelfService', name: 'Self Service' },
};

export const studioProduct = {
  Unknown,
  Broadcast: { id: 1, value: 'Broadcast', name: 'Broadcast' },
  Webcast: { id: 2, value: 'Webcast', name: 'Webcast' },
};

export const auditLogActionTypes = [
  { id: 1, value: 'Created', name: 'Created' },
  { id: 2, value: 'Updated', name: 'Updated' },
];

export const mmcResellerTypes = {
  nnt: { id: 1, value: 'NTT', name: 'NTT' },
  cpu: { id: 2, value: 'CPU', name: 'CPU' },
  Other: { id: 3, value: 'Other', name: 'Other' },
};
